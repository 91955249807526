
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import ModalC1 from './SwitchModals/ModalC1.vue';
import ModalC2 from './SwitchModals/ModalC2.vue';
import ModalC3 from './SwitchModals/ModalC3.vue';
import ModalC4 from './SwitchModals/ModalC4.vue';
import ModalC5 from './SwitchModals/ModalC5.vue';
import ModalC6 from './SwitchModals/ModalC6.vue';
import ModalC7 from './SwitchModals/ModalC7.vue';
import ModalC8 from './SwitchModals/ModalC8.vue';
import ModalC9 from './SwitchModals/ModalC9.vue';
import ModalC10 from './SwitchModals/ModalC10.vue';
import ModalC11 from './SwitchModals/ModalC11.vue';
import ModalC12 from './SwitchModals/ModalC12.vue';
import ModalC13 from './SwitchModals/ModalC13.vue';
import ModalC14 from './SwitchModals/ModalC14.vue';
import ModalExpenses from './OperationalModals/ModalExpenses.vue';
import ModalIncome from './OperationalModals/ModalIncome.vue';
import ModalCash from './OperationalModals/ModalCash.vue';
import { Inject } from 'inversify-props';
import { TYPES } from '@/core/config/Types';
import { ActivityTransactionalUpdateStatus } from '@/tracking/application/uses_cases/activitiesTransactional/update/ActivityTransactionalUpdateStatus';
import { PreRegisterFindByPk } from '@/wms/application/preRegister/search/PreRegisterFindByPk';
import { BillOfLanding } from '@/wms/domain/billOfLanding/BillOfLanding';
import { DocumentHeader } from '@/courier/domain/DocumentsV2/Documents_V2';
import { CreateFileV2 } from '@/settings/application/uses_cases/file/create/CreateFileV2';
import { FindFile } from '@/settings/application/uses_cases/file/search/FindFile';
import TransactionalEventForTraceability from '@/wms/domain/preRegister/TransactionalEventForTraceability';
import { ActivityTransactionalForTraceability } from '@/tracking/domain/activitiesTransactional/Projections/ActivityTransactionalForTraceability';
import { ActivityTransactional } from '@/tracking/domain/activitiesTransactional/ActivityTransactional';
import { preRegisterSteps } from '@/wms/domain/preRegister/PreRegisterStatusEnum';
import { PreRegisterForTraceabilityPanel } from '@/wms/domain/preRegister/PreRegisterForTraceabilityPanel';
import { FilePondFile } from 'filepond';

@Component({
  name: 'EventModal',
  components: {
    ModalC1,
    ModalC2,
    ModalC3,
    ModalC4,
    ModalC5,
    ModalC6,
    ModalC7,
    ModalC8,
    ModalC9,
    ModalC10,
    ModalC11,
    ModalC12,
    ModalC13,
    ModalC14,
    ModalExpenses,
    ModalIncome,
    ModalCash
  }
})
export default class EventModal extends Vue {
  @Inject(TYPES.ACTIVITIES_TRANSACTIONAL_UPDATE_STATUS)
  readonly updateStatusActivity!: ActivityTransactionalUpdateStatus;
  @Inject(TYPES.PRE_REGISTER_FIND_BY_PK)
  readonly findByPk!: PreRegisterFindByPk;
  @Inject(TYPES.SAVE_FILE)
  readonly saveFiles!: CreateFileV2;
  @Inject(TYPES.FIND_FILE_BY_NAME)
  readonly findFileByName!: FindFile;

  //Props
  @Prop() eventData!: TransactionalEventForTraceability;
  @Prop() activities!: ActivityTransactionalForTraceability[];
  @Prop() eventCode!: number;
  @Prop() eventName!: string;
  @Prop() masterNumber!: number;
  @Prop({ type: String, required: false }) readonly preRegisterType!: string;
  @Prop({ required: true }) master!: PreRegisterForTraceabilityPanel;
  @Prop({ type: String, required: true }) readonly lineSerial!: string;
  // @Prop({ type: Array, required: true }) readonly houses!: any[];
  @Prop({ type: String, required: false }) readonly typeOperation!: string;
  @Prop({ type: String, required: false }) readonly typeTransport!: string;
  @Prop({ type: Function }) funcEventExec!: any;
  @Prop({ required: false }) customModalIdGenerator!: string;
  @Prop({ type: Function }) searchFunction!: () => Promise<void>;
  @Prop({ type: Function }) searchActivities!: (event: TransactionalEventForTraceability) => Promise<void>;

  isLoading = false;
  switchId = '';
  switchDescription: any = '';
  idModal = '';
  activityDescription = '';
  sendNotification = false;
  disableModalExpenses = true;
  disableModalIncome = true;
  activityData: ActivityTransactionalForTraceability | null = null;
  expensesSwitchs = ['3', '4', '31', '32'];
  incomeSwitchs = ['1', '2', '21', '22', '23'];

  attachedFiles: any = [];

  get sortActivities() {
    return this.activities.filter(activity => activity.sw).sort((a, b) => a.sequence - b.sequence);
  }

  async close() {
    try {
      this.disableModalExpenses = true;
      this.disableModalIncome = true;
      await this.searchFunction();
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  goToCube(activity: ActivityTransactionalForTraceability) {
    if (preRegisterSteps[this.master.state] > preRegisterSteps.cubed) {
      //Este registro ya ha sido cubicado y no se puede volver a cubicar, solo debe poder ver en otra pestaña
      return;
    } else if (preRegisterSteps[this.master.state] > preRegisterSteps.cubed) {
      //Este registro no ha pre registrado, solo debe poder ver en otra pestaña
      return;
    }

    this.$router.push({
      name: 'warehouseCube',
      query: {
        type: this.master.type,
        number: String(this.master.number),
        activityId: String(activity.activityId),
        eventId: String(this.eventData.eventId)
      }
    });
  }

  goToLocation(activity: ActivityTransactionalForTraceability) {
    if (preRegisterSteps[this.master.state] > preRegisterSteps.located) {
      //Este registro ya ha sido localizado y no se puede volver a cubicar, solo debe poder ver en otra pestaña
      return;
    } else if (preRegisterSteps[this.master.state] < preRegisterSteps.cubed) {
      //Este registro no ha sido localizado, solo debe poder ver en otra pestaña
      return;
    }
    this.$router.push({
      name: 'warehouseLocate',
      query: {
        type: this.master.type,
        number: String(this.master.number),
        activityId: String(activity.activityId),
        eventId: String(this.eventData.eventId)
      }
    });
  }

  goToTagging(activity: ActivityTransactionalForTraceability) {
    if (preRegisterSteps[this.master.state] < preRegisterSteps.cubed) {
      //Este registro registro solo puede verse cuando se ha cubicado, por lo menos
      return;
    }
    this.$router.push({
      name: 'warehouseTagging',
      query: {
        type: this.master.type,
        number: String(this.master.number),
        activityId: String(activity.activityId),
        eventId: String(this.eventData.eventId)
      }
    });
  }

  goToReceipt(activity: ActivityTransactionalForTraceability) {
    if (preRegisterSteps[this.master.state] > preRegisterSteps.received_in_warehouse) {
      //Este registro ya ha sido recibido en almacén y no se puede volver a ejecutar la operación, solo debe poder ver en otra pestaña
      return;
    } else if (preRegisterSteps[this.master.state] < preRegisterSteps.located) {
      //Este registro no ha sido localizado, solo debe poder ver en otra pestaña
      return;
    }
    this.$router.push({
      name: 'WarehouseReceipt',
      query: {
        type: this.master.type,
        number: String(this.master.number),
        activityId: String(activity.activityId),
        eventId: String(this.eventData.eventId)
      }
    });
  }

  goToDispatch(activity: ActivityTransactionalForTraceability) {
    if (preRegisterSteps[this.master.state] > preRegisterSteps.warehouse_dispatched) {
      //Este registro ya ha sido despachado en almacén y no se puede volver a ejecutar la operación, solo debe poder ver en otra pestaña
      return;
    }
    this.$router.push({
      name: 'warehouseDispatch',
      query: {
        type: this.master.type,
        number: String(this.master.number),
        activityId: String(activity.activityId),
        eventId: String(this.eventData.eventId)
      }
    });
  }

  setModalData(activity: ActivityTransactionalForTraceability) {
    // if (this.verifyIfBulksInConsolidated() && !['0', '11', '16'].includes(activity.sw)) {
    //   this.$bvToast.toast(`${this.$t('general.bulksInConsolidated')}`);
    //   return;
    // }

    switch (activity.sw) {
      case '53':
        this.goToTagging(activity);
        return;
      case '54':
        this.goToLocation(activity);
        return;
      case '12':
        this.goToReceipt(activity);
        return;
      case '11':
        this.goToDispatch(activity);
        return;
    }

    this.switchId = activity.sw;
    this.switchDescription = this.$t(`switchs.${this.switchId}`);
    this.activityDescription = activity.description;
    this.sendNotification = activity.sendNotification;
    this.idModal = activity.keyModal;
    this.activityData = activity;

    this.$bvModal.show(
      this.expensesSwitchs.includes(activity.sw)
        ? 'modal-expenses'
        : this.incomeSwitchs.includes(activity.sw)
        ? 'modal-income'
        : activity.keyModal
    );
  }

  verifyIfBulksInConsolidated() {
    // return this.master?.bulks?.some(bulk => bulk.consolidated);
    return false;
  }

  async saveProcess(activityData: ActivityTransactional) {
    try {
      this.isLoading = true;
      const convertedFiles: File[] = this.attachedFiles.map((file: FilePondFile, index: number) => {
        //Debemos concatenar el numero de operacion, el tipo, el evento y el id de la actividad y la extension del archivo para poder identificarlo
        const newName = `${this.master.type.replaceAll('_', '-')}-${this.master.number}_E${this.eventData.eventId}_A${
          activityData.activityId
        }_D${index}.${file.file.type.split('/')[1]}`;
        return new File([file.file], newName, { type: file.file.type });
      });
      // Si hay archivos adjuntos, debemos subirlos al servidor, de lo contrario, asignamos un arreglo vacio
      activityData.files = [];
      if (convertedFiles.length > 0) {
        // Debemos procesar los archivos adjuntos, subirlos al servidor y luego llamar al servicio de actualizar la actividad asignando las rutas de los archivos adjuntos
        const response = await this.saveFiles.execute({
          files: convertedFiles,
          folder: 'activity'
        });
        if (Array.isArray(response)) {
          activityData.files = response;
        } else {
          activityData.files = [response];
        }
      }
      this.isLoading = true;
      const payload = Object.assign({}, activityData);
      await this.updateStatusActivity.execute(payload);
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  containerInspectionSave(activityData: ActivityTransactional) {
    try {
      const payload = Object.assign({}, activityData);
      // Para los conceptos de los container quitamos las referencias circulares que tienen en los packingUnits
      payload.freightInspectionList?.map(freightInspection => {
        if (freightInspection.packingUnits)
          freightInspection.packingUnits = { ...freightInspection.packingUnits, concepts: [] };
        return freightInspection;
      });
      payload.containerInspectionList?.map(containerInspection => {
        if (containerInspection.container)
          containerInspection.container = { ...containerInspection.container, concepts: [] };
        return containerInspection;
      });

      this.saveProcess(payload);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async saveForms({
    document,
    billOfLanding,
    airWayBill
  }: {
    document?: DocumentHeader;
    billOfLanding?: BillOfLanding;
    airWayBill?: BillOfLanding;
  }) {
    // try {
    //   this.isLoading = true;
    //   this.activityData.documentHeader = document ?? null;
    //   this.activityData.billOfLanding = billOfLanding ?? null;
    //   this.activityData.airWayBill = airWayBill ?? null;
    //   const updatedActivity = await this.updateStatusActivity.execute(this.activityData);
    //   if (!('error' in updatedActivity) || !('message' in updatedActivity)) {
    //     const gettedPreRegister = await this.findByPk.execute({
    //       type: this.master.type,
    //       number: this.master.number
    //     });
    //     if (!('error' in gettedPreRegister) || !('message' in gettedPreRegister)) {
    //       const objectUpdated = Object.assign(this.master, gettedPreRegister);
    //       this.master = objectUpdated;
    //       this.$bvModal.hide(
    //         ['3', '4', '31', '32'].includes(this.activityData?.sw)
    //           ? 'modal-expenses'
    //           : ['1', '2', '21', '22', '23'].includes(this.activityData?.sw)
    //           ? 'modal-income'
    //           : (this.activityData?.aswitch?.keyModal as string)
    //       );
    //       this.$bvModal.hide(`modal-event-${this.eventCode}`);
    //     }
    //   }
    //   this.activityData = new ActivityTransactional();
    //   this.isLoading = false;
    // } catch (error) {
    //   this.isLoading = false;
    //   throw new Error(`${error}`);
    // }
  }

  handleSelected(event: any, item: ActivityTransactionalForTraceability) {
    const isClicked = event.currentTarget.classList.contains('selected');
    document.querySelector('.selected')?.classList.remove('selected');

    if (!isClicked) event.currentTarget.classList.toggle('selected');

    // Si no se ha ejecutado la actividad, se habilitan los botones de Costos y Gastos e Ingresos
    if (!item.executed) {
      // Si la actividad es de tipo 3, 4, 31 o 32, se habilita el botón de Costos y Gastos
      if (!['3', '4', '31', '32'].includes(item.sw) && !['1', '2', '21', '22', '23'].includes(item.sw)) {
        this.disableModalExpenses = true;
        this.disableModalIncome = true;
      }

      // Si la actividad es de tipo 1, 2, 21, 22 o 23, se habilita el botón de Ingresos
      if (['3', '4', '31', '32'].includes(item.sw)) {
        this.disableModalExpenses = false;
        this.disableModalIncome = true;
      }

      // Si la actividad es de tipo 1, 2, 21, 22 o 23, se habilita el botón de Ingresos
      if (['1', '2', '21', '22', '23'].includes(item.sw)) {
        this.disableModalIncome = false;
        this.disableModalExpenses = true;
      }
      this.activityData = item;
    }
  }

  async onCloseActivityModal() {
    await this.searchActivities(this.eventData);
  }
}
