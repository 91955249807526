
import { TYPES } from '@/core/config/Types';
import { FindTransactionalActivityById } from '@/tracking/application/uses_cases/activitiesTransactional/search/FindTransactionalActivityById';
import { ActivityTransactional } from '@/tracking/domain/activitiesTransactional/ActivityTransactional';
import { ActivityTransactionalForTraceability } from '@/tracking/domain/activitiesTransactional/Projections/ActivityTransactionalForTraceability';
import { PreRegisterForTraceabilityPanel } from '@/wms/domain/preRegister/PreRegisterForTraceabilityPanel';
import TransactionalEventForTraceability from '@/wms/domain/preRegister/TransactionalEventForTraceability';
import PickingAndPackingCenter from '@/wms/infrastructure/ui/PickingAndPacking/PickingAndPackingCenter.vue';
import { BvModalEvent } from 'bootstrap-vue';
import { Inject } from 'inversify-props';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import FooterModal from './FooterModals/FooterModal.vue';
import HeaderModal from './HeaderModals/HeaderModal.vue';

@Component({
  name: 'ModalC13',
  components: { HeaderModal, FooterModal, PickingAndPackingCenter }
})
export default class ModalC13 extends Vue {
  @Inject(TYPES.ACTIVITIES_TRANSACTIONAL_FIND_BY_ID)
  private readonly findActivityById!: FindTransactionalActivityById;

  @Prop() isLoading!: boolean;
  @Prop() eventData!: TransactionalEventForTraceability;
  @Prop() activityData!: ActivityTransactionalForTraceability;
  @Prop({ type: Function }) saveProcess!: (activityData: ActivityTransactional) => Promise<void>;
  @Prop() attachedFiles!: any[]; // Toca cambiar el tipo de dato
  @Prop() master!: PreRegisterForTraceabilityPanel;
  @Prop({ type: Function }) searchActivities!: () => Promise<void>;

  modalActivityData: ActivityTransactional = new ActivityTransactional();

  $refs!: {
    pickingCenter: PickingAndPackingCenter;
  };

  @Watch('attachedFiles')
  onMyFilesChange() {
    // Debemos hacer que el padre actualice el valor de attachedFiles
    this.$emit('update:attachedFiles', this.attachedFiles);
  }

  get localIsLoading() {
    return this.isLoading;
  }

  set localIsLoading(value) {
    this.$emit('update:isLoading', value);
  }

  get eventExecuted() {
    return this.eventData.state === 'EXECUTED';
  }

  get modalTitle() {
    return `${this.eventData.description} - ${this.$t('switchs.' + this.activityData?.sw)} - ${
      this.activityData.keyModal
    }`;
  }

  async save(event: BvModalEvent) {
    event.preventDefault();
    try {
      await this.$refs.pickingCenter.executePick();
      this.saveProcess(this.modalActivityData);
    } catch (error) {
      throw new Error(`Error al ejecutar el proceso de picking: ${error}`);
    }
  }

  async findActivityData() {
    try {
      if (!this.modalActivityData) return;

      this.localIsLoading = true;

      this.modalActivityData = await this.findActivityById.execute({
        activityId: this.activityData?.activityId,
        eventId: this.eventData.eventId,
        preRegisterNumber: this.master.number,
        preRegisterType: this.master.type
      });
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.localIsLoading = false;
    }
  }
}
